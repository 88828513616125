import React from 'react'

function PersonalTerm() {
    return (
        <div className='text-white bg-dark mt-5 mb-5 pt-5 pb-5 w-100 px-5' style={{}}>
            서비스이용약관
            <br />1 장 [총칙]
            <br />제 1 조 (목적)
            <br />
            <br />이 약관은 “주식회사 에스에이치컴퍼니(이하 “회사”)”가 제공하는 “티지”와 관련하여 회사와 이용자 간의 권리와 의무 및 필요한 사항을 정하는데 그 목적이 있습니다
            <br />제 1 조 (목적)
            <br />이 약관은 “주식회사 에스에이치컴퍼니 (이하 “회사”)”가 제공하는 “티지”와 관련하여 회사와 이용자 간의 권리와 의무 및 필요한 사항을 정하는데 그 목적이 있습니다.
            <br />제 2 조 (용어의 정의)
            <br />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />
            <br />1)  “티지(이하 “서비스”)”란 DJ로 정의된 이용자들이 모바일에서 채팅 대화나 게시글 작성 등을 통해 참여할 수 있는 채팅 및 커뮤니티 플랫폼입니다.
            <br />
            <br />2) “이용자“란 본 약관에 동의하고 회사가 제공하는 서비스 이용 자격을 부여받은 자를 의미합니다.
            <br />
            <br />3)“청소년 이용자” 란 위 정의된 “이용자” 중 민법 제4조에 따라 성년에 이르지 아니한 만 19세 미만자를 말합니다.
            <br />
            <br />4)“앱스토어(오픈마켓)사업자“란 서비스를 설치하고 결제할 수 있도록 제공하는 전자상거래 제공자 일체(서비스내 결제를 제공하는 사업자 포함)를 말합니다. (예. 구글 플레이, 앱스토어, 애플 앱스토어, 세틀뱅크, 티스토어, 올레마켓, 유플러스 앱마켓 등)
            <br />
            <br />5)“포인트”이란 이용자가 서비스 내에서 채팅을 보내거나 게시글 상단 고정 등의 유료 기능을 이용하기 위하여 충전, 포인트 사용 등을 통해 구매한 재화를 말합니다.
            <br />
            <br />6)“충전”이란 이용자가 회사에서 제공하는 결제 수단을 통해 금전을 포인트로 전환하는 행위를 말합니다.
            <br />
            <br />7)“포인트”란 본 서비스 내에서 이용자가 구매한 “포인트”의 가치(서비스 이용 및 결제 수수료 등 제외)를 표기하는 단위로서, 금전적 보상, 포인트 또는 아이템 교환 등 회사가 정하는 방식의 범위 안에서 사용할 수 있습니다.
            <br />
            <br />8)“환불”이란 이용자가 충전한 포인트를 금전으로 되돌려 받는 것을 말합니다.
            <br />
            <br />9)“탈퇴”란 이용자가 서비스 이용 계약을 종료시키는 의사표시를 말합니다.
            <br />
            <br />10)“인증 정보”란 휴대전화번호를 통한 본인인증 서비스를 통하여 획득한 정보를 말합니다.
            <br />
            <br />2.      이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령에서 정하는 바를 따릅니다.
            <br />
            <br />제 3 조 (약관의 효력과 변경)
            <br />1.회사는 서비스의 앱 초기화면에 이 약관을 명시합니다.
            <br />
            <br />2.이 약관은 이용자가 서비스를 이용함과 동시에 효력이 발생하며, 이 약관에 동의하지 않을 경우 이용자는 서비스 이용계약을 철회할 수 있습니다.
            <br />
            <br />3. 회사는 이 약관을 관련 법령에 위배되지 않는 범위에서 변경할 수 있습니다.
            <br />
            <br />4. 변경된 약관은 적용일 및 변경내용, 변경사유 등을 명시하여 적용일 7일 이전부터 적용일 전일까지 서비스에 고지한 후 적용일부터 효력이 발생됩니다.
            <br />
            <br />5. 이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴할 수 있습니다.
            <br />
            <br />6.변경된 약관 공지 시 이용자가 명시적으로 거부의사를 표시하지 아니하거나 이용약관의 적용일 이후에도 서비스를 계속 사용하는 경우에는 변경된 이용약관에 동의한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 이용자가 회사에 명시적으로 "거부"의 의사표시를 하지 않거나 변경된 이용약관의 적용일 이후에도 서비스를 계속 사용하는 경우에는 변경된 약관에 동의한 것으로 간주합니다.
            <br />제 2 장 [이 용]
            <br />제 4 조 (이용 계약의 성립)
            <br />1. 서비스 이용 계약은 서비스를 이용하려는 자가 본 약관에 동의하고 서비스를 이용함으로써 성립됩니다.
            <br />
            <br />제 5 조 (서비스 가입 및 계정)
            <br />1.이용자는 회사가 제공하는 방식을 통해 서비스에 가입할 수 있으며, 회사는 서비스에 가입한 이용자에게 닉네임, 고유아이디가 포함된 계정을 제공합니다.
            <br />
            <br />2.이용자는 본인 여부를 확인하기 위한 휴대폰 인증 서비스 진행 시 진실한 정보만을 등록 해야 하고, 거짓된 정보 등록으로 발생하는 손해는 이용자에게 책임이 있으며, 이로 인하여 제3자로부터의 법적 쟁송이 발생할 경우 해당 이용자는 회사를 면책하고 관련하여 발생한 모든 손해를 배상하여야 합니다.
            <br />
            <br />3.이용자는 자신의 계정을 성실히 관리하여야 합니다. 이용자가 본인의 계정을 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 모든 책임이 이용자에게 있습니다.
            <br />
            <br />제 6 조 (개인정보의 보호 및 관리)
            <br />1.이용자가 이 약관에 따라 회사에 정보를 제공할 경우에는 진실된 정보를 제공하여야 하며, 이용자는 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.
            <br />
            <br />2.회사는 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.
            <br />
            <br />3.이용자 개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사가 별도로 고지하는 개인정보처리방침에 따릅니다.
            <br />
            <br />제 7 조 (회사의 의무)
            <br />1.회사는 관계 법령을 준수하고, 이 약관에서 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 수행합니다.
            <br />
            <br />2.회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선의 노력을 다합니다.
            <br />
            <br />3.회사는 이용자들이 서비스를 편리하게 이용할 수 있도록 최선의 노력을 다합니다.
            <br />
            <br />4.회사는 이용자가 제기한 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게 처리하기 위해 노력합니다.
            <br />
            <br />제 8 조 (이용자의 의무)
            <br />1. 이용자는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각호를 포함하여 당사가 정한 운영정책을 위반하는 행위를 해서는 안 됩니다.
            <br />
            <br />1)본인이 아닌 다른 이의 정보를 도용하거나 회사 관리자를 사칭하는 행위
            <br />
            <br />2)서비스 이용 중 허위 사실을 기재하여 타인을 기만하거나 서비스 운영을 방해하는 행위
            <br />
            <br />3) 회사가 제공한 계정을 타인에게 양도하는 행위
            <br />
            <br />4) 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제, 유통하여 상업적으로 이용하는 행위
            <br />
            <br />5)사기성, 음란성, 사행성, 혐오성 메시지 혹은 음성 등이 담긴 콘텐츠를 방송하거나 게시하는 행위
            <br />
            <br />6)타인의 명예를 훼손하거나 사이버 불링, 폭력적인 행위를 하는 등 이용자의 정상적인 서비스 이용을 방해하는 행위
            <br />
            <br />7)저작권을 포함한 지적재산권, 초상권 등 회사 및 제 3자의 기타 권리를 침해하는 행위
            <br />
            <br />8)타인의 개인정보를 동의없이 수집, 저장, 유포, 게시하는 행위
            <br />
            <br />9)일반적이지 않은 방식으로 데이터를 조작하여 회사의 서비스 운영을 방해하는 행위
            <br />
            <br />10)컴퓨터 소프트웨어, 하드웨어, 전기 통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위
            <br />
            <br />11)서버 해킹, 데이터 유출, 버그 등을 통해 서비스를 변경하거나 회사가 정하지 않은 방식으로 서비스를 이용하는 행위
            <br />
            <br />12)관계 법령, 서비스이용약관, 운영정책, 공지사항을 위배하는 행위
            <br />
            <br />13)기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 방식의 행동을 하는 행위
            <br />
            <br />2. 이용자는 제 1항에 정한 운영정책 위반 행위를 발견할 시, ‘고객문의’, ‘신고하기’ 등을 통해 신고를 접수할 수 있습니다.
            <br />
            <br />3. 회사는 이용자가 제1항에 정한 운영정책을 위반하여 회사의 서비스에 지장을 초래한 경우, 사전 통보 없이 기간을 정하여 서비스 이용을 제한할 수 있습니다.
            <br />
            <br />4. 회사는 이용자의 이용 제한이 정당한 경우 이로 인하여 이용자가 입은 손해에 대해 배상하지 않습니다.
            <br />
            <br />제 9 조 (게시물에 대한 저작권)
            <br />
            <br />1. 이용자가 서비스 내에 게시한 콘텐츠의 저작권은 이용자 본인에게 있습니다.
            <br />
            <br />2. 서비스 이용 시 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 행위는 금지됩니다. 서비스 이용 중 저작권과 관련하여 발생하는 분쟁에 대한 모든 책임은 이용자에게 있으며, 이와 관련하여 제3자로부터 회사를 상대로 법적문제가 제기될 경우 이용자는 회사를 면책하고 이로 인한 모든 손해를 배상하여야 합니다.
            <br />
            <br />3. 본 조 제1항에 따라 이용자가 서비스 내에 게시한 콘텐츠의 저작권과 관련하여 권리 침해 등이 발생할 경우 이에 대한 침해 신고 및 게시 중단, 재개 요구 절차는 다음과 같습니다.
            <br />
            <br />- 다 음 -
            <br />
            <br />저작권 또는 기타 지적 재산권 침해 청구 통지 및 절차
            <br />
            <br />1) 회사는 타인의 지적 재산권을 존중하고 이에 대한 보호를 매우 중요하게 생각하며 모든 이용자에게도 서비스 사용 중 지적 재산권을 존중할 것을 권고하고 있습니다. 이용자가 서비스 내 지적 재산권을 침해하는 경우 당 사의 지적 재산권 침해 운영 정책에 의거합니다.
            <br />
            <br />2) 저작권 침해 위반 운영 정책
            <br />회사는 서비스 내에서 타인의 저작권 또는 지적 재산권을 침해하는 행위에 대해 저작권법 제102조(온라인서비스제공자의 책임 제한) 이하 및 본 약관에 따라 게시된 콘텐츠의 게시를 중단할 수 있으며, 반복적으로 침해하는 이용자의 계정에 대해 계정 해지 및 서비스에 대한 접근을 차단할 수 있습니다.
            <br />
            <br />3) 저작권 침해 신고 및 게시 중단 요구 절차
            <br />누구든지 서비스에서 또는 서비스를 통해 제공되는 콘텐츠가 자신이 소유하거나 관리하는 지적 재산권을 침해하는 방식으로 사용되었다고 판단되어 해당 게시물의 게시, 복제, 전송을 중단시키고자 하는 사람은(이하 “권리주장자”) 저작권법 시행규칙 별지 제40호 서식에 따른 전송 복제 중단 요청서 를 작성하여 서식에 기재된 첨부서류들과 함께 아래 기재된 회사의 권리침해신고센터로 접수하여 주시기 바랍니다. 권리주장자가 회사에 제출한 “전송 복제 중단 요청서” 및 첨부서류는, 이를 접수한 회사 외에도 관련 콘텐츠를 게시함으로써 저작권을 침해하였다고 주장된 이용자에게도 공개될 수 있고, 권리주장자는 이에 동의하는 것으로 간주합니다.
            <br />
            <br />4) 권리침해신고센터 접수처성명 및 소속부서명
            <br />
            <br />성명 및 소속부서명: ㈜에스에이치컴퍼니
            <br />전자우편번호: tgDev95@gmail.com
            <br />우편주소: 경기도 수원시 영통구 영통동 1014-13
            <br />5) 게시 중단 요구 접수 후 절차
            <br />회사는 권리주장자로부터 권리침해사실을 적법하게 소명하는 복제ㆍ전송의 중단요구를 받은 경우에 영업일 기준 24시간 내 그 게시물의 게시, 복제, 전송을 중단시키고 접수된 전송 복제 중단 요청서에 기재된 내용을 바탕으로 사실을 권리주장자 및 해당 게시물의 게시자에게 통보할 수 있습니다.
            <br />
            <br />6) 게시, 전송, 복제의 재개 요구
            <br />자신의 게시물이 권리주장자의 요구에 따라 게시, 복제, 전송이 중단되었음을 회사로부터 통보받은 이용자는 자신의 게시, 복제, 전송이 정당한 권리에 의한 것임을 소명하여 그 게시, 복제ㆍ전송의 재개를 요구할 수 있습니다. 이를 위하여 이용자는 저작권법 시행규칙 별지43호 서식에 따른 전송 복제 재개 요청서 를 작성하여 서식에 기재된 첨부서류들과 함께 회사의 권리침해신고센터에 제출하여야 합니다.
            <br />
            <br />7) 콘텐츠의 게시, 복제, 전송의 재개
            <br />회사가 이처럼 정당한 권리를 소명하는 복제전송재개요청서 및 첨부서류들을 접수한 경우, 회사는 재개요구사실 및 재개예정일을 접수된 전송 복제 재개 요청서에 기재된 내용을 바탕으로 권리주장자에게 통보하고 그 예정일에 게시, 복제, 전송을 재개할 수 있습니다. 다만, 권리주장자가 해당 침해행위에 대하여 소를 제기한 사실을 재개예정일 전에 회사에게 통보한 경우에 회사는 해당 게시물의 게시, 복제, 전송을 재개하지 않습니다.
            <br />
            <br />8) 저작권 침해에 대한 이용자에 대한 통지
            <br />회사는 서비스에 게시된 콘텐츠의 저작권 및 지적 재산권의 침해 주장 통지를 서비스 내 이용자의 공지 게시판을 통해 이용자에게 고지할 수 있습니다. 이 고지내용은 타 서비스 이용자에게도 알려질 수 있으므로 이용자가 콘텐츠를 게시할 때 타인의 저작권 및 지적 재산권을 존중할 것을 권고합니다. 이용자는 서비스 사용과 관련된 문제에 관해 공개적으로 통지받음으로써 발생할 수 있는 모든 책임 또는 손해는 이용자 개인에게 있음을 알려드립니다.
            <br />
            <br />9) 회사는 본 저작권 침해 위반 정책을 변경할 권리가 있으며 이용자는 이 약관의 변경 사항을 주기적으로 확인할 의무가 있습니다. 다만, 본 정책의 변경으로 이용자의 실질적인 권리 또는 의무가 수정되는 경우 서비스를 지속적으로 사용하기 위해 수정된 약관에 동의해야 할 수 있습니다. 본 정책에 따라 발생하는 분쟁은 분쟁이 발생한 시점에 유효했던 약관의 정책에 따라 해결됩니다.
            <br />
            <br />4. 회사는 이용자가 게시한 콘텐츠를 국내외에서 제공하는 관련 서비스 목적(홍보 등)으로 사용할 수 있습니다.
            <br />
            <br />5. 제4항의 사용범위는 이용자가 서비스 내에 게시한 콘텐츠의 복제, 수정, 전시, 전송, 배포, 출판 및 2차 저작물과 편집 저작물 작성을 포함합니다.
            <br />
            <br />6. 회사가 이용자가 게시한 콘텐츠를 상업적 혹은 제 4항에서 정하지 않은 방식으로 사용할 경우, 회사는 전화, 팩스, 전자 우편 등의 방식으로 이용자에게 동의를 얻는 절차를 거치며, 별도의 보상을 할 수 있습니다.
            <br />
            <br />제 10 조 (서비스의 제공 시간 및 중지, 변경)
            <br />
            <br />1. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 서비스를 제공합니다.
            <br />
            <br />2. 회사는 시스템 정기점검 등 서비스 운영상 필요한 경우, 일정기간 동안 서비스를 일시 중지시킬 수 있으며, 이러한 경우 회사는 그 내용 및 시간을 서비스 내에 공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
            <br />
            <br />3. 회사는 새로운 서비스, 각종 버그 패치 등 서비스 운영 또는 기술상 필요한 경우에는 제공하고 있는 서비스의 전부 또는 일부를 수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용 및 일자 등에 대해서는 서비스 내에 공지함으로써 이용자에 대한 통지를 대체합니다.
            <br />
            <br />4. 회사는 서비스의 기획이나 운영상 또는 회사의 긴박한 상황 등에 의해 서비스 전부를 중단할 필요가 있는 경우 서비스 내에 이를 공지하고 서비스의 제공을 중단할 수 있습니다. 단, 회사가 통제할 수 없는 사유로 인한 경우 공지없이 서비스가 중단될 수 있습니다.
            <br />
            <br />제 11 조 (광고의 게재 및 전송)
            <br />
            <br />1. 회사는 본 서비스를 유지하기 위하여 서비스 내에 광고를 게재할 수 있습니다.
            <br />
            <br />2. 서비스에 게재되는 제3자가 주체인 광고에 이용자가 참여하거나 거래함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
            <br />
            <br />3. 회사는 다양한 전자적 전송매체(SMS/MMS/e-mail/App push 등)를 통해 이용자에게 마케팅 정보 등을 제공할 수 있으며, 이용자는 이에 동의합니다.
            <br />
            <br />4. 이용자는 법령 등에 따른 거래관련정보, 고객문의 등에 대한 답변, 설문 등을 제외하고는 언제든지 마케팅 수신 거부를 할 수 있으며, 이 경우 회사는 즉시 마케팅 정보 등을 제공하는 행위를 중단합니다.
            <br />
            <br />
            <br />제 12 조 (계약 해지)
            <br />
            <br />1. 이용자는 서비스 이용을 원하지 않는 경우, 회원탈퇴를 통하여 언제든지 이용 계약을 해지할 수 있으며 탈퇴 신청 이후에는 탈퇴 취소 및 탈퇴한 계정에 대해 복구할 수 없습니다.
            <br />
            <br />2. 이용자의 탈퇴 신청 1일 후 계정 탈퇴 및 개인 정보 삭제가 완료됩니다.
            <br />
            <br />3. 이용자는 계정 탈퇴 완료 이후에 서비스에 재가입이 가능합니다.
            <br />
            <br />제 13 조 (구매 취소)
            <br />
            <br />1. 이용자가 구매한 포인트는 구매일로부터 7일 이내에 별도의 수수료 없이 구매 취소가 가능합니다.
            <br />
            <br />2. 회사는 구매 취소 신청자로부터 제공받은 개인 정보 및 구매 내역을 확인하여 구매 취소를 진행합니다. 이 경우 회사는 이용자의 정당한 구매 취소 사유를 확인하기 위해 추가 증빙자료를 요구할 수 있습니다. (예. 플랫폼에 등록된 개인정보, 앱스토어의 구매 또는 결제 내역, 고객의 의도와 상이하게 타인을 통해 구매되었다는 것을 확인할 수 있는 서류(가족관계 증명서) 등)
            <br />
            <br />3. 다음 각 호의 경우 포인트의 구매 취소가 제한될 수 있습니다.
            <br />
            <br />1) 구매 후 7일이 지난 포인트의 경우
            <br />
            <br />2) 유료 기능을 통해 이미 포인트를 사용한 경우(이용자의 의도에 반하여 타인에 의해 사용되었을 경우도 포함)
            <br />
            <br />3) 포인트를 사용하여 앱 내 기능(채팅방 생성 및 5초 사진 전송 등의 포인트를 사용하는 기능)을 구매 및 선물한 경우
            <br />
            <br />4) 구매 당시 지급받은 포인트의 수량과 구매취소를 원하는 포인트의 수량에 차이가 있을 경우 (구매 당시 이벤트를 통해 추가 지급받은 아이템 또는 포인트의 수량에 차이가 있을 경우도 포함)
            <br />
            <br />5) 이벤트, 광고, 보상 등 무상으로 제공받은 포인트의 경우
            <br />
            <br />6) 앱스토어(오픈마켓) 사업자의 결제 정책에 따라 구매 취소가 거절된 경우
            <br />
            <br />7) 이용자의 부주의로 인해 앱스토어(오픈마켓)의 구매 취소 가능 기간이 경과한 경우
            <br />
            <br />8) 청소년 이용자가 사술 등을 통해 성년자로 믿게 하여 구매, 사용한 경우
            <br />
            <br />9) 이용자가 서비스를 탈퇴하여 구매 내역 등 정보 확인이 불가한 경우
            <br />
            <br />
            <br />4. 청소년 이용자가 법정대리인의 동의 없이 결제한 경우, 청소년 이용자 또는 법정대리인은 포인트 구매를 취소할 수 있습니다. 이 경우 회사는 최초 본인 인증이 진행된 인증 정보 명의자를 기준으로 청소년 이용자 여부를 판단합니다. 회사는 필요한 경우 구매 취소 신청자에게 청소년 이용자 및 법정대리인을 증명할 수 있는 서류를 요청할 수 있습니다. 다만 청소년이 다른 성년자 등의 주민등록번호를 이용하여 본인 인증을 하는 등 사술로써 회사로 하여금 성년자인 것으로 믿게 한 경우에는 법정대리인의 동의가 없었더라도 해당 구매를 취소할 수 없습니다.
            <br />
            <br />제 14 조 (환불)
            <br />
            <br />1. 이용자가 본 이용약관 제 15조에 따라 구매 취소를 한 경우 회사는 지체 없이 해당 포인트를 회수하고 구매 취소를 접수한 날로부터 3 영업일 이내에 환불을 진행합니다.
            <br />
            <br />2. 회사가 이용자에게 환불을 지연한 때에는 그 지연기간에 대하여 「전자상거래법」에서 정하는 지연이자를 지급합니다. 단, 이용자의 귀책사유로 인한 경우 또는 결제사의 정책에 따라 환불이 지연되는 경우 지연이자를 지급하지 않습니다.
            <br />
            <br />3. 회사의 귀책사유로 인하여 구매한 포인트를 이용하지 못하는 경우, 동일한 수량의 포인트를 제공하거나 해당 구매 금액을 구매일에 관계없이 전액 환불합니다.
            <br />
            <br />4. 환불은 회사 및 각 앱스토어 사업자의 환불 정책에 따라 진행됩니다.
            <br />
            <br />5. 회사는 서비스 종료 시 이용자의 구매 취소 신청 여부와 관계없이 이용자가 보유한 포인트를 전액 환불합니다.
            <br />
            <br />
            <br />제 3 장 [구 독]
            <br />제 1 조 (목적)
            <br />본 약관은 에스에이치컴퍼니(이하 “회사“)가 제공하는 서비스의 이용과 관련하여 할인된 요금에 일정 기간 서비스 이용을 보장하는 회사의 정기 구독 서비스(이하 “정기 구독 서비스“)에 가입 및 결제한 회원(이하 “구독자“) 사이의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다.
            <br />본 약관의 적용 대상은 “구독자“에 한합니다.
            <br />
            <br />제 2 조 (정기 구독 서비스 가입과 결제방식)
            <br />회원은 정기 구독 서비스에 가입하기 위하여 앱내 버튼을 클릭하여 정기 구독 서비스 가입 화면(이하 “구독 안내 화면“)에서 가입할 수 있습니다. 회원은 '월 5,900으로 시작하기' 버튼을 클릭함으로써 회사와 구독 계약을 체결하게 되며, 구독자는 구매 시점에 제시된 가격으로 구독자에게 계약기간 동안의 구독료를 청구하도록 허용합니다.
            <br />회사가 구독계약 체결 전 자동으로 기간이 갱신된다는 사실을 고지한 경우에는, 구독자의 구독은 구독자 본인이 직접 종료하거나, 회사에서 이를 종료하지 않는 한, 계약기간 마지막 일자에 자동으로 갱신됩니다. 구독의 종료를 원할 경우 구독자는 매기간 마지막 일자에 갱신되기 전에 구독 서비스 가입 화면에 명시된 방법에 따라 구독을 해지해야 합니다.
            <br />
            <br />
            <br />
            <br />제 3 조 (정기 구독 서비스 이용 및 개시)
            <br />구독자는 계약기간 동안 티지 앱 내의 여러 기능(좋아요 회원 열람, 방문 회원 열람, 시크릿 모드, 채팅 확인 유무 확인, 광고 제거 등)을 자유롭게 이용할 수 있으며, 구독 서비스를 결제한 순간부터 계약기간이 개시됩니다.
            <br />
            <br />
            <br />제 4 조 (정기 구독 서비스 해지 방법)
            <br />ios - 구독 서비스는 구독자가 현재 구독 기간 만료 시점으로부터 최소 24시간 전에 iTunes 스토어의 설정에서 해지할 수 있습니다.
            <br />
            <br />
            <br />제 5 조 (구독 철회)
            <br />구독자는 구독 결제일 이후 프리미엄 기능(좋아요 회원 열람, 방문 회원 열람, 시크릿 모드, 채팅 확인 유무 확인, 광고 제거 등)을 1회라도 이용했거나 구독 결제일 이후 7일이 지난 경우 구독을 철회할 수 없습니다. 만약 구독자가 구독 서비스를 전혀 이용하지 않아 구독 서비스 결제일 이후 7일 이내 철회를 원할 경우 회사의 고객센터로 연락함으로써 구독 철회를 요구할 수 있으며 회사는 3 영업일 내에 전액 환불합니다.
            <br />
            <br />
            <br />제 6 조 (구독제 변경 및 중단)
            <br />회사는 구독자의 구독 혜택을 유지하기 위해 합리적으로 운영을 지속할 의무가 있습니다. 단, 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우 정기 구독 서비스의 일부 또는 전부를 중단할 수 있습니다.
            <br />① 악천후, 천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제, 기타 회사의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우
            <br />② 기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우
            <br />③ 회사의 시스템을 포함한 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우
            <br />④ 제3자와의 제휴를 통하여 제공되는 서비스의 경우에 해당 제휴사업자의 사정에 따라 변경되거나 중지되는 경우
            <br />⑤ 기타 회사의 원활한 운영을 현저히 저해하는 사유가 발생한 경우 회사는 위 각 호의 어느 하나에 해당하는 사유로 인해 “정기 구독 서비스“의 내용이 변경 또는 중단되는 경우 이로 인해 구독자가 입은 손해에 대하여 고의 또는 과실이 없는 이상 책임을 부담하지 않습니다.
            <br />
            <br />제 7 조 (종료)
            <br />정기 구독 서비스가 종료되거나 해지된 즉시 자동으로 프리미엄 기능의 이용이 중단됩니다.
            <br />
            <br />
            <br />제 4 장 [기 타]
            <br />
            <br />제 1 조 (손해배상)
            <br />
            <br />이용자가 이 약관에서 정한 사항을 위반함으로써 회사 또는 제3자에게 손해를 입힌 경우 이용자는 회사 또는 제3자에게 그 손해를 배상하여야 합니다.
            <br />
            <br />제 2 조 (책임제한)
            <br />
            <br />회사는 서비스의 이용장애 혹은 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중과실로 인한 경우를 제외하고는 서비스의 이용장애, 변경 및 중지로 인하여 이용자에게 발생하는 손해에 대해서는 책임이 면제됩니다.
            <br />회사는 이용자가 서비스를 이용하여 기대하는 점수, 순위 등을 얻지 못한 것에 대해서는 책임이 면제됩니다.
            <br />회사는 이용자가 본인의 개인정보 등(계정 포함)을 변경하여 얻는 불이익 및 정보 상실에 대해서는 책임이 면제됩니다.
            <br />회사는 이용자 상호간 또는 이용자와 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
            <br />제 3 조 (약관 외 준칙)
            <br />
            <br />1. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래법」, 「약관 규제법」,「정보통신망법」, 「콘텐츠산업진흥법」 등 관계 법령에 따릅니다.
            <br />
            <br />2. 회사는 본 약관 외에 운영정책, 개인정보처리방침 등 개별 약관을 둘 수 있습니다.
            <br />
            <br />제 4 조 (재판권 및 준거법)
            <br />
            <br />1. 회사와 이용자 간에 제기된 소송에는 대한민국 법을 적용합니다.
            <br />
            <br />2. 서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 대해서는 민사소송법의 주소지를 관할하는 법원을 합의관할로 합니다.
            <br />
            <br />
            <br />
            <br />이 약관은 2023년 06월 26일부터 시행합니다.
            <br />
            <br />
            <br />
            <br />공시일자 : 2023년 06월 26일
            <br />시행일자 : 2023년 06월 26일
        </div>
    )
}

export default PersonalTerm