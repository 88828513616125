import React from 'react'
import GoogleButton from '../assets/google-play-badge.png'
import IosButton from '../assets/ios-white.svg'
import Main0 from '../assets/tg-main0.png'
import Main from '../assets/tg-main1.png'
import Main2 from '../assets/tg-main2.png'
import Main3 from '../assets/tg-main3.png'
import Main4 from '../assets/tg-main4.png'
import Main5 from '../assets/tg-main5.png'
import QR from '../assets/qr.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
function TgMain() {
    return (
        <>
            <div className='bg-dark pb-5'>
                <div className='bg-dark pb-5'>

                    <div className='container-fluid min-vh-100 w-100 tg-background d-flex main-container pt-5'>
                        <div>
                            <h1 className='text-center text-white pt-5 display-4'>
                                {`트랜스젠더, 시디, 러버의`}
                            </h1>
                            <h1 className='text-center text-white display-3 '>
                                {`새로운 소통공간, 티지`}
                            </h1>
                        </div>
                        <div className='justify-content-end mt-auto'>
                            <div className='w-100 row'>
                                <div className='col-md-6 col-md-push-8 d-flex justify-content-center'>
                                    <div className='row w-100 justify-content-center'>
                                        <img className='col-md-7 col-lg-7 col-sm-8 col-8 align-self-center' style={{ objectFit: 'contain' }} src={Main2} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-8 col-md-pull-6 mt-5 mx-auto mb-3'>
                                    <div className='d-flex justify-content-center m-auto h-100'>
                                        <div className='d-grid align-items-center'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='col-md-10 col-lg-10 d-flex col-sm-8 col-8'>
                                                    <div>
                                                        <div>
                                                            <a className='w-100' href='https://play.google.com/store/apps/details?id=com.sh.tgapplication'>
                                                                <img className='w-100' src={GoogleButton} />
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className='w-100' href='https://apps.apple.com/us/app/%ED%8B%B0%EC%A7%80-%ED%8A%B8%EB%9E%9C%EC%8A%A4%EC%A0%A0%EB%8D%94-cd-%EC%8B%9C%EB%94%94-%EB%9F%AC%EB%B2%84-%ED%8A%B8%EC%A0%A0-%EA%B2%8C%EC%9D%B4-%EB%B0%94%EC%9D%B4-mtf/id6446443415'>
                                                                <img className='w-100' src={IosButton} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center'>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main3} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid '>
                                    <div>
                                        <h5 className='text-white'>
                                            "최근 접속한 순서대로"<br /><br /><br />
                                        </h5>
                                        <h6 className='text-white'>
                                            현재 활동하고 있는 유저들을 실시간으로 확인할 수 있어요 <br /><br />
                                            이미 많은 트랜스젠더, 시디, 러버들이 활동하고 있어요
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main4} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white'>
                                            "간편한 소통"<br /><br />
                                        </h5>
                                        <h6 className='text-white'>
                                            게시판에 게시글을 올려 <br />
                                            내 게시글에 관심이 있는 사람을 <br />
                                            알 수 있어요 <br /> <br />
                                        </h6>
                                        <h6 className='text-white'>
                                            다른 게시글에 관심이 있다면 <br />
                                            "관심 있어요" 를 눌러보세요! <br />
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main5} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white'>
                                            "간편한 대화"<br /><br />
                                        </h5>
                                        <h6 className='text-white'>
                                            게시글이나 유저리스트를 보고, <br />
                                            마음에 드는 상대와 바로 채팅을 <br />
                                            시작할 수 있어요.
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div >
                    <h1 className='text-white text-center mt-5'>
                        지금 바로 시작해보세요
                    </h1>
                    <div className='d-flex justify-content-center col-6 mx-auto'>
                        <img className='col-6 mt-3 mx-auto' src={QR} />
                    </div>
                    <div className='d-flex justify-content-center text-white mt-3 mb-5 fs-5'>
                        QR코드로 앱스토어로 이동
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className=' d-flex justify-content-center'>
                                <a className='col-10 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center' href='https://play.google.com/store/apps/details?id=com.sh.tgapplication'>
                                    <img className='w-75' src={GoogleButton} />
                                </a>
                            </div>
                        </div>
                        <div className='col-6 d-grid'>
                            <div className=' d-flex justify-content-center'>
                                <a className='col-10 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center' href='https://apps.apple.com/us/app/%ED%8B%B0%EC%A7%80-%ED%8A%B8%EB%9E%9C%EC%8A%A4%EC%A0%A0%EB%8D%94-cd-%EC%8B%9C%EB%94%94-%EB%9F%AC%EB%B2%84-%ED%8A%B8%EC%A0%A0-%EA%B2%8C%EC%9D%B4-%EB%B0%94%EC%9D%B4-mtf/id6446443415'>
                                    <img className='w-75' src={IosButton} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-light mt-5 p-5' >
                    <div className=''>
                        에스에이치컴퍼니 <div className='d-inline text-black-50'>|</div>  대표 : 김승훈 <div className='d-inline text-black-50'>|</div>  소재지 : 경기도 수원시 영통구 영통동 1014-13 202 <br /> <br />
                        사업자등록번호 : 878-52-00547 <div className='d-inline text-black-50'>|</div>  통신판매업 신고번호 : 2012-수원영통-0471 <div className='d-inline text-black-50'>|</div> 대표번호 : 0507-1387-4665
                    </div>
                </div>
            </div >
        </>
    )
}

export default TgMain;
