import React from 'react'

function PersonalTerm() {
    let [selectedVersion, setSelectedVersion] = React.useState<number>(0)
    return (
        <div className='text-white bg-dark mt-5 mb-5 pt-5 pb-5 w-100 px-5' style={{}}>
            {selectedVersion === 0 ? <>
                <br />개인정보처리방침 Ver1.1
                <br />㈜에스에이치컴퍼니(이하 '회사'라 함)는 최소의 개인정보를 필요한 시점에 수집하며, 수집한 개인정보는 이용자에게
                <br />고지하고 동의 받은 범위내에서만 이용합니다. 회사는 사전에 동의 받은 내용의 범위를 초과해서 이용하거나 외부에
                <br />공개하지 않습니다.
                <br />
                <br />회사는 이용자의 개인정보를 안전하게 처리하기 위해 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고
                <br />있습니다. 회사는 개인정보처리방침의 개정이 있을 경우 사전에 이용자분들에게 알리겠으며, 이용자분이 확인하실 수
                <br />있도록 공지사항 등을 통해 게시하도록 하겠습니다.
                <br />
                <br />㈜에스에이치컴퍼니 개인정보처리방침은 다음과 같은 내용을 포함하고 있습니다.
                <br />● 개인정보 처리 목적
                <br />● 개인정보의 제3자 제공
                <br />● 개인정보 처리위탁
                <br />● 이용자의 권리와 그 행사방법
                <br />● 개인정보 파기 절차 및 방법
                <br />● 티지의 정보보호 활동
                <br />● 개인정보의 기술적/관리적 보호대책
                <br />● 개인정보자 동 수집장치의 설치/운영 및 거부에 관한 사항
                <br />● 스마트폰 앱 관리
                <br />● 이용자의 의무
                <br />● 개인정보 보호 책임자 및 민원 처리 담당 부서
                <br />● 개인정보 보호 침해 신고 및 상담
                <br />● 기타 사항
                <br />● 고지의 의무
                <br />
                <br />제 1 조 (개인정보 처리 목적)
                <br />
                <br />1. 단계별 처리하는 개인정보 항목
                <br />1) 본인인증시(필수)
                <br />휴대전화번호
                <br />※ 휴대폰번호가 이후 앱 내 로그인을 위한 아이디로 활용됩니다.
                <br />2) 회원가입시(필수 및 선택)
                <br />필수항목 : 휴대전화번호, 서비스 이용 기록, 접속 로그, 접속 IP 정보, 결제기록, 주소지, 나이
                <br />선택항목 : 프로필 사진, 자기소개, 성향정보
                <br />3) 로그인시(필수)
                <br />휴대전화번호
                <br />4) 프로필수정시(선택)
                <br />필수항목 : 휴대전화번호, 서비스 이용 기록, 접속 로그, 접속 IP 정보, 결제기록, 주소지, 나이
                <br />선택항목 : 프로필 사진, 자기소개, 성향정보
                <br />4) 구매시: 대행사에서 수집
                <br />5) 금전적 보상 요청시(환전시) (필수)
                <br />이용자성명, 휴대전화번호, 이메일, 주민등록번호(온라인수집)
                <br />※ 국세 기본법에 의한 소득 신고 및 원천징수 영수증 발급 목적
                <br />환전받는분정보: 예금주, 은행명, 계좌번호(온라인수집)
                <br />※ 금전적 보상 절차에 따라 본인확인 서류정보가 추가로 수집 및 처리될 수 있습니다.
                <br />6) 결제관련문의(구매일반/취소/오류) 시(필수)
                <br />회원번호, 닉네임, 이메일, 휴대전화번호, 결제내역(결제일시, 결제금액, 결제상품)
                <br />7) 고객문의시(필수)
                <br />이메일, 문의내용(온라인수집)
                <br />※ 서비스 이용개선 및 분쟁해결을 위해 본인확인 서류정보가 추가로 수집 및 처리될 수 있습니다.
                <br />8) 이벤트참여시(필수)
                <br />이름, 아이디, 닉네임, 연락처, 이메일(온라인수집)
                <br />9) 마케팅활용동의시(선택)
                <br />성별, 생년월일, 닉네임, 연락처, 휴대전화번호, SNS 프로필정보(이메일, 닉네임, 이름) (온라인수집)
                <br />※ 본인인증, 성인인증, 법정대리인인증, 서비스 이용 및 고객 문의(금전적 보상 요청, 구매 취소, 문의 등) 시 안내
                <br />받은 본인확인 서류를 통해 단계별 필요한 정보가 최소 수집 및 처리될 수 있습니다.
                <br />
                <br />2. 개인정보 처리목적
                <br />1) 서비스가입, 서비스이용및상담, 프로필수정, 부정이용 확인 및 방지를 위하여 개인정보를 처리합니다.
                <br />2) 본인 및 연령확인을 위하여 개인정보를 처리합니다.
                <br />3) 사용자를 위한 추천 콘텐츠 제공을 위하여 개인정보를 처리합니다.
                <br />4) 다양한 콘텐츠를 빠르고 효율적인 검색하기 위한 기능을 필수로 제공하고 있으며, 이를 위해 이용자의 개인정보를
                <br />처리합니다. 이 경우 개인정보는 가명 처리되어, 개인을 알아볼 수 없도록 활용됩니다.
                <br />5) 구매 및 구매취소 관련 업무, 금전적 보상 관련 업무(계좌인증, 실명인증, 원천징수 영수증 발급, 소득신고), 환불
                <br />요청 대응, 이용자 상담 및 대응, 고지사항 전달, 계정 제재 및 이용 제한 조치를 위하여 개인정보를 처리합니다.
                <br />6) 건전한 콘텐츠생성, 서비스품질개선, 불법촬영물 등의 유통방지를 위하여 개인정보를 처리합니다.
                <br />7) 이벤트 참여, 광고성 정보 제공 등 마케팅 목적으로 개인정보를 처리 할 수 있으며, 이 경우 사전에 이용자의 별도
                <br />동의를 받습니다.
                <br />8) 서비스 이용기록 분석, 서비스 이용에 대한 통계 및 분석에 따른 맞춤 서비스 제공 등에 개인정보를 처리 할 수
                <br />있습니다.
                <br />
                <br />3. 개인정보 처리 및 보유기간
                <br />회사는 개인정보 수집시 동의받은 기간 동안 개인정보를 처리 및 보유합니다.
                <br />1) 회사가 수집 ∙ 이용한 개인정보는 이용자의 탈퇴 시 즉시 파기됩니다. 단, 부정 이용 및 재가입 방지를 위해 필요한
                <br />정보를 1일간 보관 후 파기됩니다.
                <br />2) 채팅 서비스, 게시글 작성 이용시 건전한 콘텐츠 생성, 서비스 품질 개선, 불법촬영물
                <br />등의 유통 방지, 청소년 보호 등을 목적으로 신고가 들어올 시 90일간 보관 후 즉시 파기되며 이외 정보들은 즉시
                <br />파기됩니다.
                <br />3) 회사는 서비스 이용기록 분석, 서비스이용에 대한 통계및 분석을 위해 개인정보를 처리할경우 복원이 불가능 하도록
                <br />비식별조치 할 수 있습니다.
                <br />4) 탈퇴한 회원의 일부 개인정보는 관련 법령에 따라 보관이 필요한 경우 별도의 DB로 이전되어 분리보관됩니다.
                <br />대금 결제 및 재화 등의 공급에 관한 기록 (5년) /계약 또는 청약 철회 등에 관한 기록(5년) / 소비자 불만 또는 분쟁
                <br />처리에 관한 기록(3년) *전자상거래 등에서의 소비자 보호에 관한 법률
                <br />원천징수 신고에 관한기록(5년) *소득세법, 국세기본법
                <br />※ 별도 분리보관된 이용자의 개인정보는 최소한의 취급자만 접근 가능하도록 통제하고 있습니다.
                <br />
                <br />제 2 조 (개인정보의 제3자 제공)
                <br />1. 회사는 원칙적으로 수집 및 이용 목적 범위를 초과하거나 이용자의 동의없이 개인정보를 제 3자에게 제공하지
                <br />않습니다.
                <br />2. 이용자의 개인정보를 제3자에게 제공해야 될 경우 관련법령에서 요구하는 개인정보 제3자 제공에 관한 사항을
                <br />이용자에게 명확히 안내하고 동의를 받겠습니다.
                <br />3. 회사는 법령에 근거가 있는 경우 이용자의 동의없이 개인정보를 제공할 수 있습니다.
                <br />1) 법률에 특별한 규정이있거나 법령상 의무를 준수하기 위한 경우
                <br />2) 수사목적으로 압수수색 검증 영장 등에 따른 수사 기관의 요구가 있는 경우
                <br />3) 수사기관등에서 자살, 실종 등과 같은 범죄가 의심되는 긴급 사안의 업무 협조를 요청할 경우
                <br />
                <br />제 3 조 (개인정보 처리 위탁)
                <br />1. 회사는 이용자에게 보다 나은 서비스를 제공하기 위해 반드시 필요한 경우 개인정보 처리 업무 일부를 외부에 위탁할
                <br />수도있습니다.
                <br />2. 회사는 개인정보 처리 업무 위탁 시 계약 등 문서를 통해 수탁자에게 개인정보보호 관련 법령을 준수하도록 책임을
                <br />요구하고 있습니다.
                <br />3. 회사는 개인정보를 처리 위탁하는 경우 계약된 수탁자가 개인정보보호 관련 법령에서 요구하는 안전성 확보 조치
                <br />기준을 충족하여 업무를 진행하도록 계약서에 관련 내용을 반영하고 있습니다.
                <br />4. 회사는 수탁자에게 개인정보의 안전한 처리, 개인정보 접근 또는 접속 기록 관리, 목적 외 이용 금지, 위탁업무의
                <br />재위탁 제한, 암호화 등 이용자의 개인정보를 안전하게 처리하기 위한 기술적 관리적 보호조치를 수탁자에게 요구하고
                <br />있으며, 수탁자가 개인정보를 안전하게 관리하고 있는 지정기적으로 관리 감독을 진행하고있습니다. 단, 수탁자의 보안
                <br />수준 및 규모 등을 고려하여 관리감독을 진행하고 있습니다.
                <br />5. 서비스 이용을 위해 국내업체에 개인정보의 처리를 위탁하는 현황은 아래와 같습니다.
                <br />● NCP: 본인인증(휴대전화번호로 sms발송)
                <br />● 회원탈퇴시 혹은 서비스종료시까지(이용자가 해당서비스를 이용하는 경우에만 처리위탁됨)
                <br />6. 서비스 이용을 위해 해외업체에서 개인정보를 처리위탁 및 보관하는 현황은 아래와같습니다.
                <br />● 구글 /firebase,미국, 이메일/앱푸시 발송, 서비스 이용 시점에 네트워크를 통한 전송, 성명,
                <br />이메일및서비스, 이용관련 정보 회원탈퇴 또는 위탁계약 종료시까지
                <br />
                <br />제 4 조 (이용자의 권리와 그 행사 방법)
                <br />1. 이용자는 언제든지 본인의 개인정보를 조회하거나 수정할 수 있으며 회원 탈퇴 절차를 통해 이용자 본인의 개인정보를
                <br />철회 할 수 있습니다.
                <br />2. 개인정보의 수정을 원하거나 회원탈퇴를 원하는 경우 다음과 같은 절차에 의해 진행할 수 있습니다.
                <br />1) 이용자는 회사의 App의 설정을 통해 개인정보를 수정하실 수 있습니다.
                <br />2) 이용자는 tgDev95@gmail.com을 통해서도 수정, 삭제 등을 요청하실 수 있습니다. 다만, 해당 요청을 하는
                <br />정보주체가 본인임을 명확하게 확인하기 위한 업무상 절차가 요구될 수 있습니다. 이 경우 해당 업무 처리를 위해
                <br />규제기관에서 별도로 규정하고 있는 표준 서식을 통해 접수요청을 할 수도 있으며, 이에 따라 진행이 가능할 수
                <br />있습니다.
                <br />3) 이용자는 서비스를 탈퇴하고자 하는 경우 [설정] 메뉴의[탈퇴하기]를 클릭하여 탈퇴하실 수 있습니다.
                <br />3. 이용자가 개인정보 수정, 삭제, 탈퇴 등 권리를 요청했다 하더라도 이용자의 귀책사유에 따른 회사의 서비스 정지 정책이
                <br />적용 중인 경우 본 권리요청이 즉시 적용되지 않을 수 있습니다.
                <br />4. 이용자가 동의 철회, 개인정보의 삭제 또는 처리 정지를 요청했다하더라도, 다른 법령에 따라 수집하거나, 보존되어야만
                <br />하는 경우 법령준수에 의한 보존 기한이 우선 적용될 수 있습니다.
                <br />5. 이용자가 서비스 이용기록 등 열람∙ 조회 요청 시 제3자의 정보가 포함된 경우 해당 정보는 열람∙조회 처리에 제한이
                <br />있을 수 있습니다.
                <br />6. 이용자는 개인정보의 열람·정정·삭제·처리정지 요구 및 요구거절에 대한 이의제기를 하기 위한 창구는 아래와 같습니다.
                <br />메일: tgdev95@gmail.com
                <br />7. 이용자는 만 19세 이상인 경우에만 회원 가입이 가능하며, 원칙적으로 만 19세 미만 아동의 개인정보는 수집하지
                <br />않습니다.
                <br />
                <br />제 5 조 (개인정보 파기절차 및 방법)
                <br />1.회사는 이용자의 개인정보가 업무 목적 달성 또는 보유 기간을 달성하여 파기가 필요한 경우, 해당 개인정보를 즉시
                <br />파기하고 있습니다. 다만 관련 법령에 의해 보관해야하는 정보는 개별 법령이 정한 일정기간을 준수하고 있습니다.
                <br />2,개인정보의 파기 절차 및 방법은 아래와 같습니다.
                <br />1) 이용자의 개인정보 수집 및 이용 목적이 달성되면 지체 없이 파기됩니다. 종이에 출력된 개인정보는 분쇄기로
                <br />분쇄하거나 소각을 통하여 파기되며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
                <br />사용하여 삭제합니다.
                <br />2) 다른 법령에 의해서 보존되어야 하는 개인정보의 경우에는 별도의 DB에 관련 법령 및 내부 규정을 준수를 위해
                <br />일정기간동안 안전하게 보관된 후 파기됩니다.
                <br />
                <br />제 6 조 (개인정보의 기술적/관리적 보호대책)
                <br />회사는 이용자 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                <br />다음과 같은 기술적/관리적 보호 대책을 마련하고있습니다.
                <br />1.개인정보의 암호화
                <br />1) 회사는 법령에서 요구하는 암호화를 수행하고 있습니다. 계좌번호 등 금융정보는 법령에서 요구하는 알고리즘을
                <br />충족하여 저장 및 관리되고 있습니다.
                <br />2) 회사는 이용자의 개인정보가 전송되는 구간의 암호화를 하고 있으며, 사내에서 업무 수행 시 중요 데이터는
                <br />암호화하거나, 파일에 암호를 설정하여 사용하고 있습니다.
                <br />2.해킹 등에 대비한 대책
                <br />1) 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
                <br />있습니다.
                <br />2) 회사는 더욱 향상된 보안조치를 확보할 수 있도록 가능한 기술적 보호조치를 확보하기 위하여 지속적으로 노력하고
                <br />있습니다.
                <br />3) 회사는 외부로부터의 무단 접근을 통제하고 있으며, 시스템 보안성을 확보하기 위한 가능한 모든 기술적 장치를
                <br />갖추려 노력하고 있습니다.
                <br />3.개인정보 취급자 최소화 및 지속적 교육
                <br />1) 회사는 개인정보를 취급하는 직원을 최소화하고 있으며, 개인정보 취급자에 대한 교육을 정기 및 수시로 실시하여 본
                <br />방침의 이행 및 개인정보 보호관련 법령 준수 여부를 확인하고 지속적으로 강조하고 있습니다.
                <br />2) 개인정보 취급자는 해당 권한이 적절한지에 대한 검토가 이루어지고 있으며, 최소한의 권한이 부여될 수 있는 조치를
                <br />진행하고있습니다.
                <br />3) 회사는 개인정보 취급자의 계정 비밀번호를 정기적으로 갱신하고 있습니다.
                <br />
                <br />제 7 조 (스마트폰 앱 관리)
                <br />1.스마트폰 앱을 통하여 서비스를 이용 시 단말기 정보 등에 접근할 필요가 있는 경우에는 이를 고지하고 승인을
                <br />얻습니다.
                2.스마트폰 앱 접<br />근 권한에 대해 권한을 변경하실 경우에는 스마트폰 상에서 '설정{`>`}애플리케이션관리자'에서 변경이
                <br />가능합니다.
                <br />
                <br />제 8 조 (이용자의 의무)
                <br />1.이용자는 본인의 개인정보를 안전하게 지키기 위해 개인정보보호에 관한 법률을 준수할 의무가 있습니다.
                <br />2.이용자는 자신의 개인정보를 안전하게 보호할 의무가 있으며, 회사는 이용자 개인의 부주의로 인해 발생된 일에
                <br />대해서는 책임을 지지 않습니다.
                <br />3.이용자는 본인의 개인정보를 최신의 상태로 정확하게 입력하고 유지할 의무가 있습니다. 이용자의 부정확한 정보
                <br />입력으로 인하여 발생하는 문제의 책임은 이용자 본인에게 있습니다.
                <br />4. 이용자는 타인의 개인정보를 도용하여 회원가입 또는 서비스 이용 시 회원자격 상실과 함께 개인정보에 관한 법률에
                <br />의거하여 처벌될 수 있습니다.
                <br />5. 이용자는 타인의 개인정보를 침해하거나 유출하지 않을 의무도 가지고 있습니다. 서비스 이용 중 다른 이용자에 대해
                <br />알게된 개인정보를 언급하거나, 유출할 경우 개인정보 관련법령에 의거하여 처벌될 수 있습니다.
                <br />제 9 조 (개인정보보호 책임자 및 민원 처리 담당 부서)
                <br />● 개인정보보호책임자
                <br />- 성명: 김승훈
                <br />- 직급: 대표
                <br />- 직책 : CEO(대표)
                <br />- 연락처: tgDev95@gmail.com
                <br />● 개인정보민원처리담당부서
                <br />- 부서명: 서비스운영
                <br />- 연락처: tgDev95@gmail.com
                <br />● 회사가 제공하는 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해 구제 등에 관한
                <br />사항을 개인정보 보호 책임자 및 민원처리 부서로 문의하실 수 있으며, 회사는 이용자들의 신고 및 문의사항에 대해
                <br />신속하게 충분한 답변을 드릴 것 입니다.
                <br />제 10 조 (개인정보보호 침해 신고 및 상담)
                <br />기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                <br />- 개인정보분쟁조정위원회(www.kopico.go.kr/ 전화1833-6972)
                <br />- 개인정보침해신고센터(privacy.kisa.or.kr/ 국번없이118)
                <br />- 대검찰청 사이버범죄수사단 (www.spo.go.kr/ 02-3480-3571)
                <br />- 경찰청사이버수사국(https://ecrm.cyber.go.kr/minwon/main)/ 국번없이182
                <br />
                <br />제 11 조 (기타 사항)
                <br />1.회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트
                <br />및 자료에 대해 통제할 수 있는 권한이 없기 때문에 외부 사이트를 통해 제공 받는 서비스나 자료의 유용성에 대해
                <br />책임질 수 없으며 보증할 수 없습니다. 따라서 회사의 페이지가아닌 타 사이트의 페이지로 옮겨 가실 경우 반드시 해당
                <br />사이트의 신뢰성을 확인하기 바라며, 방문하신 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의
                <br />개인정보 처리방침 등 정책을 확인해주시기 바랍니다.
                <br />
                <br />제 12 조 (고지의 의무)
                <br />이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는
                <br />변경사항의 시행 7일 전부터 공지사항 등을 통하여 알려드릴 것입니다. 또한 관련 법령이나 회사 정책의 변경으로
                <br />불가피하게 개인정보처리방침을 변경해야 하는 경우, 공지사항을 통해 빠르게 알려드리고 있으니 참고하여 주시기
                <br />바랍니다.
                <br />
                <br />개인정보처리방침버전번호: Ver1.1
                <br />공지일자: 2023년08월02일
                <br />시행일자: 2023년08월02일
            </> :
                <>
                    {'<'} 에스에이치컴퍼니 {'>'}('https://www.shcompany.link'이하 '티지')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

                    <br />○ 이 개인정보처리방침은 2023년 2월 25부터 적용됩니다.
                    <br />
                    <br />
                    <br />제1조(개인정보의 처리 목적)
                    <br />
                    <br />{'<'} 에스에이치컴퍼니 {'>'}('https://www.shcompany.link'이하 '티지')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                    <br />
                    <br />1. 홈페이지 회원가입 및 관리
                    <br />
                    <br />회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지 목적으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    <br />2. 민원사무 처리
                    <br />
                    <br />민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    <br />3. 재화 또는 서비스 제공
                    <br />
                    <br />서비스 제공, 콘텐츠 제공, 본인인증을 목적으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    <br />4. 마케팅 및 광고에의 활용
                    <br />
                    <br />접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />제2조(개인정보의 처리 및 보유 기간)
                    <br />
                    <br />① {'<'} 에스에이치컴퍼니 {'>'}은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
                    <br />
                    <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                    <br />
                    <br />1.{'<'}홈페이지 회원가입 및 관리{'>'}
                    <br />{'<'}홈페이지 회원가입 및 관리{'>'}와 관련한 개인정보는 수집.이용에 관한 동의일로부터{'<'}준영구{'>'}까지 위 이용목적을 위하여 보유.이용됩니다.
                    <br />보유근거 : 탈퇴시 1일 후 폐기
                    <br />관련법령 :
                    <br />예외사유 :
                    <br />
                    <br />
                    <br />제3조(처리하는 개인정보의 항목)
                    <br />
                    <br />① {'<'} 에스에이치컴퍼니 {'>'}은(는) 다음의 개인정보 항목을 처리하고 있습니다.
                    <br />
                    <br />1{'<'} 홈페이지 회원가입 및 관리 {'>'}
                    <br />필수항목 : 휴대전화번호, 서비스 이용 기록, 접속 로그, 접속 IP 정보, 결제기록, 주소지, 나이
                    <br />선택항목 : 프로필 사진, 자기소개
                    <br />
                    <br />
                    <br />제4조(만 14세 미만 아동의 개인정보 처리에 관한 사항)
                    <br />
                    <br />
                    <br />
                    <br />① {'<'}개인정보처리자명{'>'}은(는) 만 14세 미만 아동에 대해 개인정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를 수집합니다.
                    <br />
                    <br />• 필수항목 : 법정 대리인의 성명, 관계, 연락처
                    <br />
                    <br />② 또한, {'<'}개인정보처리자명{'>'}의 {'<'}처리목적{'>'} 관련 홍보를 위해 아동의 개인정보를 수집할 경우에는 법정대리인으로부터 별도의 동의를 얻습니다.
                    <br />
                    <br />③ {'<'}개인정보처리자명{'>'}은(는) 만 1 4세 미만 아동의 개인정보를 수집할 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를 확인합니다.
                    <br />
                    <br />• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 개인정보처리자가 그 동의 표시를 확인했음을 법정대리인의 휴대전화 문자 메시지로 알리는 방법
                    <br />
                    <br />• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드·직불카드 등의 카드정보를 제공받는 방법
                    <br />
                    <br />• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를 확인하는 방법
                    <br />
                    <br />• 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후 제출하도록 하는 방법
                    <br />
                    <br />• 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법
                    <br />
                    <br />• 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를 통하여 동의를 얻는 방법
                    <br />
                    <br />• 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법
                    <br />
                    <br />
                    <br />
                    <br />제5조(개인정보의 파기절차 및 파기방법)
                    <br />
                    <br />
                    <br />① {'<'} 에스에이치컴퍼니 {'>'} 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                    <br />
                    <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                    <br />1. 법령 근거 :
                    <br />2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
                    <br />
                    <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <br />1. 파기절차
                    <br />{'<'} 에스에이치컴퍼니 {'>'} 은(는) 파기 사유가 발생한 개인정보를 선정하고, {'<'} 에스에이치컴퍼니 {'>'} 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                    <br />
                    <br />2. 파기방법
                    <br />
                    <br />전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                    <br />
                    <br />종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다
                    <br />
                    <br />
                    <br />제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
                    <br />
                    <br />
                    <br />
                    <br />① 정보주체는 에스에이치컴퍼니에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                    <br />
                    <br />② 제1항에 따른 권리 행사는에스에이치컴퍼니에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 에스에이치컴퍼니은(는) 이에 대해 지체 없이 조치하겠습니다.
                    <br />
                    <br />③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                    <br />
                    <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                    <br />
                    <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                    <br />
                    <br />⑥ 에스에이치컴퍼니은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                    <br />
                    <br />
                    <br />
                    <br />제7조(개인정보의 안전성 확보조치에 관한 사항)
                    <br />
                    <br />{'<'} 에스에이치컴퍼니 {'>'}은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                    <br />
                    <br />1. 개인정보의 암호화
                    <br />이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)
                    <br />
                    <br />
                    <br />
                    <br />에스에이치컴퍼니 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
                    <br />
                    <br />
                    <br />제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
                    <br />
                    <br />
                    <br />
                    <br />행태정보의 수집·이용·제공 및 거부등에 관한 사항
                    <br />
                    <br />{'<'}개인정보처리자명{'>'}은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.
                    <br />
                    <br />
                    <br />
                    <br />제10조(추가적인 이용·제공 판단기준)
                    <br />
                    <br />{'<'} 에스에이치컴퍼니 {'>'} 은(는) ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.
                    <br />이에 따라 {'<'} 에스에이치컴퍼니 {'>'} 가(이) 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
                    <br />▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
                    <br />
                    <br />▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부
                    <br />
                    <br />▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부
                    <br />
                    <br />▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
                    <br />
                    <br />※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함
                    <br />
                    <br />
                    <br />
                    <br />제11조(가명정보를 처리하는 경우 가명정보 처리에 관한 사항)
                    <br />
                    <br />{'<'} 에스에이치컴퍼니 {'>'} 은(는) 다음과 같은 목적으로 가명정보를 처리하고 있습니다.
                    <br />
                    <br />▶ 가명정보의 처리 목적
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />▶ 가명정보의 처리 및 보유기간
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />▶ 가명정보의 제3자 제공에 관한 사항(해당되는 경우에만 작성)
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />▶ 가명정보 처리의 위탁에 관한 사항(해당되는 경우에만 작성)
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />▶ 가명처리하는 개인정보의 항목
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />▶ 법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의 안전성 확보조치에 관한 사항
                    <br />
                    <br />- 직접작성 가능합니다.
                    <br />
                    <br />제12조 (개인정보 보호책임자에 관한 사항)
                    <br />
                    <br />① 에스에이치컴퍼니 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                    <br />
                    <br />▶ 개인정보 보호책임자
                    <br />성명 :김승훈
                    <br />직책 :ceo
                    <br />직급 :ceo
                    <br />연락처 :0507-1387-4665, tgDev95@gmail.com
                    <br />※ 개인정보 보호 담당부서로 연결됩니다.
                    <br />
                    <br />
                    <br />▶ 개인정보 보호 담당부서
                    <br />부서명 :
                    <br />담당자 :
                    <br />연락처 :, ,
                    <br />② 정보주체께서는 에스에이치컴퍼니 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 에스에이치컴퍼니 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                    <br />
                    <br />
                    <br />
                    <br />제13조(국내대리인의 지정)
                    <br />
                    <br />정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 {'<'} 에스에이치컴퍼니 {'>'}의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여 연락을 취할 수 있습니다. {'<'} 에스에이치컴퍼니 {'>'}은(는) 정보주체의 개인정보 관련 고충처리 등 개인정보 보호책임자의 업무 등을 신속하게 처리할 수 있도록 노력하겠습니다.
                    <br />
                    <br />▶ {'<'} 에스에이치컴퍼니 {'>'} 은(는) ｢개인정보 보호법｣ 제39조의11에 따라 국내대리인을 지정하였습니다.
                    <br />
                    <br />- 국내대리인의 성명 : [대리인 성명_직접입력] (법인의 경우 법인명, 대표자의 성명)
                    <br />
                    <br />- 국내대리인의 주소 : [대리인 주소_직접입력] (법인의 경우 영업소 소재지)
                    <br />
                    <br />- 국내대리인의 전화번호 : [대리인 전화번호_직접입력]
                    <br />
                    <br />- 국내대리인의 전자우편 주소 : [대리인 전자우편_직접입력]
                    <br />
                    <br />제14조(개인정보의 열람청구를 접수·처리하는 부서)
                    <br />정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
                    <br />{'<'} 에스에이치컴퍼니 {'>'}은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                    <br />
                    <br />▶ 개인정보 열람청구 접수·처리 부서
                    <br />부서명 :
                    <br />담당자 :
                    <br />연락처 : , ,
                    <br />
                    <br />
                    <br />제15조(정보주체의 권익침해에 대한 구제방법)
                    <br />
                    <br />
                    <br />
                    <br />정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
                    <br />
                    <br />1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
                    <br />2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                    <br />3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                    <br />4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                    <br />
                    <br />「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
                    <br />
                    <br />※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
                    <br />
                    <br />제16조(영상정보처리기기 운영·관리에 관한 사항)
                    <br />① {'<'} 에스에이치컴퍼니 {'>'}은(는) 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.
                    <br />
                    <br />1.영상정보처리기기 설치근거·목적 : {'<'} 에스에이치컴퍼니 {'>'}의
                    <br />
                    <br />2.설치 대수, 설치 위치, 촬영 범위 :
                    <br />설치대수 : 대
                    <br />설치위치 :
                    <br />촬영범위 :
                    <br />3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 :
                    <br />
                    <br />4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법
                    <br />촬영시간 : 시간
                    <br />보관기간 : 촬영시부터
                    <br />보관장소 및 처리방법 :
                    <br />5.영상정보 확인 방법 및 장소 :
                    <br />
                    <br />6.정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용함
                    <br />
                    <br />7.영상정보 보호를 위한 기술적.관리적.물리적 조치 :
                    <br />
                    <br />
                    <br />
                    <br />제17조(개인정보 처리방침 변경)
                    <br />
                    <br />
                    <br />① 이 개인정보처리방침은 2023년 2월 25부터 적용됩니다.
                    <br />
                    <br />② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
                </>
            }
            <div style={{ cursor: 'pointer' }} onClick={() => { setSelectedVersion(0); window.scrollTo({ top: 0 }); }}>
                <div>
                    2023. 8. 2 ~ 이후 변경시까지 (최신) 적용 (클릭)
                </div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { setSelectedVersion(1); window.scrollTo({ top: 0 }); }}>
                <div>
                    2023. 2. 25 ~ 2023. 8. 1 적용 (클릭)
                </div>
            </div>
        </div>
    )
}

export default PersonalTerm