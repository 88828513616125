import React from 'react'

function PersonalTerm() {
    return (
        <div className='text-white bg-dark mt-5 mb-5 pt-5 pb-5 w-100 px-5' style={{}}>

            <br />&lt;  エスエイチカンパニー&lt;  ('https://www.shcompany.link' 以下「テージ」という)は、「個人情報保護法」第30条に基づき、情報主体の個人情報を保護し、これに関する苦情を迅速かつ円滑に処理できるようにするため、次のように個人情報処理方針を樹立·公開します。
            <br />
            <br />○ この個人情報処理方針は2023年2月25日から適用されます。
            <br />
            <br />
            <br />第1条（個人情報の処理目的）
            <br />
            <br />&lt;   エスエイチカンパニー &lt;  ('https://www.shcompany.link' 以下「テージ」という)は、次の目的のために個人情報を処理します。 処理している個人情報は次の目的以外の用途には利用されず、利用目的が変更される場合には「個人情報保護法」第18条により別途同意を得るなど必要な措置を履行する予定です。
            <br />
            <br />1. ホームページ会員登録及び管理
            <br />
            <br />会員加入意思確認、会員制サービス提供にともなう本人識別·認証、会員資格維持·管理、サービス不正利用防止、満14歳未満児童の個人情報処理時に法定代理人の同意有無確認、各種告知·通知目的で個人情報を処理します。
            <br />
            <br />
            <br />2. 民願事務処理
            <br />
            <br />請願人の身元確認、苦情事項確認、事実調査のための連絡·通知、処理結果通知目的で個人情報を処理します。
            <br />
            <br />
            <br />3. 財貨またはサービス提供
            <br />
            <br />サービス提供、コンテンツ提供、本人認証を目的に個人情報を処理します。
            <br />
            <br />
            <br />4. マーケティングおよび広告への活用
            <br />
            <br />アクセス頻度の把握または会員のサービス利用に関する統計などを目的に個人情報を処理します。
            <br />
            <br />
            <br />
            <br />
            <br />第2条（個人情報の処理及び保有期間）
            <br />
            <br />① &lt;  エスエイチカンパニー&lt;  は、法令に基づく個人情報の保有·利用期間または情報主体から個人情報を収集する際に同意された個人情報の保有·利用期間内で個人情報を処理·保有します。
            <br />
            <br />② それぞれの個人情報処理および保有期間は次のとおりです。
            <br />
            <br />1.&lt;  ホームページ会員登録及び管理&lt;
            <br />&lt;  ホームページ会員登録及び管理&lt;  に関する個人情報は収集。利用に関する同意日から&lt;  ジュンヨン区&lt;  まで上記利用目的のために保有。利用されます。
            <br />保有根拠:脱退時、1日後に廃棄
            <br />関連法令 :
            <br />例外事由 :
            <br />
            <br />
            <br />第3条（処理する個人情報の項目）
            <br />
            <br />① &lt;   エスエイチカンパニー &lt;  は、次の個人情報項目を処理しています。
            <br />
            <br />1&lt;  ホームページ会員登録及び管理&lt;
            <br />必須項目:携帯電話番号、サービス利用記録、接続ログ、接続IP情報、決済記録、住所地、年齢
            <br />選択項目: プロフィール写真、自己紹介
            <br />
            <br />
            <br />第4条（満14歳未満の児童の個人情報処理に関する事項）
            <br />
            <br />
            <br />
            <br />① &lt;  個人情報処理者名&lt;  は(は)満14歳未満の児童に対して個人情報を収集する際、法定代理人の同意を得て当該サービス遂行に必要な最小限の個人情報を収集します。
            <br />
            <br />• 必須項目:法定代理人の氏名、関係、連絡先
            <br />
            <br />② また、&lt;個人情報処理者名&lt;の&lt;処理目的&lt;関連広報のために児童の個人情報を収集する場合には法定代理人から別途同意を得ます。
            <br />
            <br />③ &lt;個人情報処理者名&lt;は(は)満14歳未満の児童の個人情報を収集する際には、児童に法定代理人の氏名、連絡先などの最小限の情報を要求することができ、次のいずれかの方法で適法な法定代理人が同意したかを確認します。
            <br />
            <br />• 同意内容を掲載したインターネットサイトに法定代理人が同意するかどうかを表示するようにし、個人情報処理者がその同意表示を確認したことを法定代理人の携帯電話文字メッセージで知らせる
            <br />
            <br />• 同意内容を掲載したインターネットサイトに法定代理人が同意するかどうかを表示するようにし、法定代理人のクレジットカード·デビットカードなどのカード情報を提供してもらう方法
            <br />
            <br />• 同意内容を掲載したインターネットサイトに法定代理人が同意するかどうかを表示するようにし、法定代理人の携帯電話本人認証などを通じて本人であるかどうかを確認する方法
            <br />
            <br />• 同意内容が書かれた書面を法定代理人に直接発給するか、郵便またはファックスを通じて伝達し、法定代理人が同意内容に対して署名捺印後に提出する方法
            <br />
            <br />• 同意内容が書かれた電子メールを送信し、法定代理人から同意の意思表示が書かれた電子メールを受け取る
            <br />
            <br />• 電話を通じて同意内容を法定代理人に知らせ、同意を得たりインターネットアドレスなど同意内容を確認できる方法を案内し、再度電話で同意を得る方法
            <br />
            <br />• その他、上記に準ずる方法で法定代理人に同意内容を知らせ同意の意思表示を確認する方法
            <br />
            <br />
            <br />
            <br />第5条（個人情報の破棄手続及び破棄方法）
            <br />
            <br />
            <br />① &lt;エスエイチカンパニー&lt;は個人情報保有期間の経過、処理目的達成など個人情報が不要になったときは、遅滞なく当該個人情報を破棄します。
            <br />
            <br />② 情報主体から同意を得た個人情報保有期間が経過し、又は処理目的が達成されたにもかかわらず、他の法令により個人情報を保存し続けなければならない場合には、当該個人情報を別途のデータベース(DB)に移したり保管場所を異にして保存します。
            <br />1. 法令根拠 :
            <br />2. 保存する個人情報項目:口座情報、取引日
            <br />
            <br />③ 個人情報破棄の手続きおよび方法は次のとおりです。
            <br />1. 破棄手続き
            <br />&lt;エスエイチカンパニー&lt;は破棄事由が発生した個人情報を選定し、&lt;エスエイチカンパニー&lt;の個人情報保護責任者の承認を得て個人情報を破棄します。
            <br />
            <br />2. 破棄方法
            <br />
            <br />電子ファイル形式の情報は、記録を再生できない技術的方法を使用します。
            <br />
            <br />紙に出力された個人情報はシュレッダーで粉砕するか焼却して破棄します
            <br />
            <br />
            <br />第6条（情報主体及び法定代理人の権利·義務及びその行使方法に関する事項）
            <br />
            <br />
            <br />
            <br />① 情報主体はSHカンパニーに対していつでも個人情報閲覧·訂正·削除·処理停止要求などの権利を行使することができます。
            <br />
            <br />② 第1項による権利行使はエスエイチカンパニーに対して「個人情報保護法」施行令第41条第1項により書面、電子メール、模写伝送(FAX)等を通じて行うことができ、エスエイチカンパニーはこれに対して遅滞なく措置します。
            <br />
            <br />③ 第1項による権利行使は、情報主体の法定代理人や委任を受けた者など代理人を通じて行うことができます。この場合、「個人情報の処理方法に関する告示（第令和2-7号）」 別紙第11号書式による委任状を提出しなければなりません。
            <br />
            <br />④ 個人情報の閲覧および処理停止要求は「個人情報保護法」第35条第4項、第37条第2項により情報主体の権利が制限されることがあります。
            <br />
            <br />⑤ 個人情報の訂正及び削除要求は、他の法令においてその個人情報が収集対象として明示されている場合には、その削除を要求することはできません。
            <br />
            <br />⑥ エスエイチカンパニーは、情報主体権利による閲覧の要求、訂正·削除の要求、処理停止の要求時に閲覧などの要求をした者が本人であるか正当な代理人であるかを確認します。
            <br />
            <br />
            <br />
            <br />第7条（個人情報の安全性確保措置に関する事項）
            <br />
            <br />&lt;エスエイチカンパニー&lt;は個人情報の安全性確保のため、次のような措置を取っています。
            <br />
            <br />1. 個人情報の暗号化
            <br />利用者の個人情報はパスワードは暗号化され保存および管理されており、本人だけが知ることができ、重要なデータはファイルおよび転送データを暗号化したりファイルロック機能を使用するなどの別途のセキュリティ機能を使用しています。
            <br />
            <br />
            <br />
            <br />
            <br />第8条（個人情報を自動的に収集する装置の設置·運営及びその拒否に関する事項）
            <br />
            <br />
            <br />
            <br />SHカンパニーは情報主体の利用情報を保存し、随時読み込む「クッキー(cookie)」を使用しません。
            <br />
            <br />
            <br />第9条（行動情報の収集·利用·提供及び拒否等に関する事項）
            <br />
            <br />
            <br />
            <br />行動情報の収集·利用·提供及び拒否等に関する事項
            <br />
            <br />&lt;個人情報処理者名&lt;はオンラインオーダーメード型広告などのための行動情報を収集·利用·提供しません。
            <br />
            <br />
            <br />
            <br />第10条（追加利用·提供判断基準）
            <br />
            <br />&lt;エスエイチカンパニー&lt;は、「個人情報保護法」第15条第3項及び第17条第4項に基づき、「個人情報保護法施行令」第14条の2に基づく事項を考慮し、情報主体の同意なしに個人情報を追加で利用·提供することができます。
            <br />これに伴い、&lt;エスエイチカンパニー&lt;が情報主体の同意なしに追加利用·提供をするために次のような事項を考慮しました。
            <br />▶ 個人情報を追加で利用·提供しようとする目的が当初の収集目的と関連があるかどうか
            <br />
            <br />▶ 個人情報を収集した情況または処理慣行に照らして、追加的な利用·提供に対する予測可能性があるかどうか
            <br />
            <br />▶ 個人情報の追加利用·提供が情報主体の利益を不当に侵害しているかどうか
            <br />
            <br />▶ 仮名処理又は暗号化等の安全性確保に必要な措置をしたか否か
            <br />
            <br />※ 追加利用·提供時の考慮事項に対する判断基準は、事業者/団体自ら自主的に判断して作成·公開する
            <br />
            <br />
            <br />
            <br />第11条（仮名情報を処理する場合、仮名情報処理に関する事項）
            <br />
            <br />&lt; エスエイチカンパニー &lt; は、次のような目的で仮名情報を処理しています。
            <br />
            <br />▶ 仮名情報の処理目的
            <br />
            <br />- 直接作成できます。
            <br />
            <br />▶ 仮名情報の処理及び保有期間
            <br />
            <br />- 直接作成できます。
            <br />
            <br />▶ 仮名情報の第三者提供に関する事項(該当する場合のみ作成)
            <br />
            <br />- 直接作成できます。
            <br />
            <br />▶ 仮名情報処理の委託に関する事項(該当する場合のみ作成)
            <br />
            <br />- 直接作成できます。
            <br />
            <br />▶ 仮名処理する個人情報の項目
            <br />
            <br />- 直接作成できます。
            <br />
            <br />▶ 法第28条の4（仮名情報に対する安全措置義務等）による仮名情報の安全性確保措置に関する事項
            <br />
            <br />- 直接作成できます。
            <br />
            <br />第12条（個人情報保護責任者に関する事項）
            <br />
            <br />① SHカンパニーは個人情報処理に関する業務を総括して責任を負い、個人情報処理に関する情報主体の苦情処理および被害救済などのために以下のように個人情報保護責任者を指定しています。
            <br />
            <br />▶ 個人情報保護責任者
            <br />氏名:キム·スンフン
            <br />役職 : ceo
            <br />職位 : ceo
            <br />連絡先 :0507-1387-4665、tgDev95@gmail.com
            <br />※ 個人情報保護担当部署につながります。
            <br />
            <br />
            <br />▶ 個人情報保護担当部署
            <br />部署名 :
            <br />担当者:
            <br />連絡先 : , 、
            <br />② 情報主体はSHカンパニーのサービス(または事業)を利用する際に発生したすべての個人情報保護に関するお問い合わせ、苦情処理、被害救済などに関する事項を個人情報保護責任者および担当部署にお問い合わせいただけます。 エスエイチカンパニー銀情報主体の問い合わせについて
        </div>
    )
}

export default PersonalTerm